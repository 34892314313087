import keyMirror from 'keymirror';

const types = keyMirror({
  UPDATE_SIGNUP_FORM: null,
  RESET_SIGNUP_FORM: null,
  SIGN_UP_USER: null,
  CREATE_TICKET: null,
  RESET_ACTIVE_TICKET: null,
  GET_TICKET: null,
  UPDATE_USER_FORM: null,
  DISCONNECT_FROM_CREATOR: null,
  CONNECT_TO_CREATOR: null,
  RESET_USER_FORM: null,
  RESET_CONNECT: null,
  GET_USER_SESSIONS: null,
});

export default types;
