import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
import AuthTypes from '../auth/types';
import {Session} from '@brightlive/shared/helpers/interfaces';

/* eslint-disable  @typescript-eslint/no-explicit-any */

export interface SignUpFormInitState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  success: unknown;
  error: unknown;
}

const signUpFormInitState: SignUpFormInitState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  success: null,
  error: null,
};

const signUpForm = (state = signUpFormInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.UPDATE_SIGNUP_FORM}`:
      return assign({}, state, {
        ...action?.data,
        error: null,
        success: null,
      });
    case `${types.RESET_SIGNUP_FORM}`:
      return assign({}, signUpFormInitState, {
        success: null,
        error: null,
      });
    default:
      return state;
  }
};
export interface UserFormInitState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  hasEmailNotifications: boolean;
  hasSmsNotifications: boolean;
  promotionalEmails: boolean;
  onBlocklist: boolean;
  avatar: string;
  displayName: string;
  roles: Array<string>;
  stripeAccountID: string;
  id: string;
  success: unknown;
  error: unknown;
}

const userFormInitState: UserFormInitState = {
  firstName: '',
  lastName: '',
  stripeAccountID: '',
  id: '',
  email: '',
  phone: '',
  hasEmailNotifications: true,
  hasSmsNotifications: true,
  promotionalEmails: false,
  onBlocklist: false,
  avatar: '',
  displayName: '',
  roles: [],
  success: null,
  error: null,
};

const userForm = (state = userFormInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.UPDATE_USER_FORM}`:
    case `${AuthTypes.UPDATE_CURRENT_USER}`:
    case `${AuthTypes.GET_CURRENT_USER}_SUCCESS`:
      return assign({}, state, {
        ...action?.data,
        error: null,
        success: null,
      });
    case `${AuthTypes.LOGOUT}_SUCCESS`:
      return assign({}, userFormInitState, {
        success: null,
        error: null,
      });
    default:
      return state;
  }
};
export interface TicketInitState {
  data: Record<string, any>;
  success: unknown;
  error: Record<string, unknown>;
}

const ticketInitState: TicketInitState = {
  data: {},
  success: null,
  error: {},
};

const currentTicket = (state = ticketInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.CREATE_TICKET}_REQUEST`:
    case `${types.GET_TICKET}_REQUEST`:
      return assign({}, state, {
        data: {},
        success: null,
        error: null,
      });
    case `${types.CREATE_TICKET}_SUCCESS`:
    case `${types.GET_TICKET}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data,
          error: null,
          success: true,
        }
      );
    case `${types.CREATE_TICKET}_FAILURE`:
    case `${types.GET_TICKET}_FAILURE`:
      return assign({}, state, {
        success: null,
        error: action.error,
      });
    case `${types.RESET_ACTIVE_TICKET}`:
      return ticketInitState;
    default:
      return state;
  }
};

export interface ConnectInitState {
  connectSuccess: null | true | false;
  disconnectSuccess: null | true | false;
  error: unknown;
}

const connectInitState: ConnectInitState = {
  connectSuccess: null,
  disconnectSuccess: null,
  error: null,
};

const connect = (state = connectInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.CONNECT_TO_CREATOR}_REQUEST`:
    case `${types.DISCONNECT_FROM_CREATOR}_REQUEST`:
      return assign({}, state, {
        connectSuccess: null,
        disconnectSuccess: null,
        error: null,
      });
    case `${types.CONNECT_TO_CREATOR}_SUCCESS`:
      return assign(
        {},
        {
          connectSuccess: true,
          disconnectSuccess: null,
          error: null,
        }
      );
    case `${types.DISCONNECT_FROM_CREATOR}_SUCCESS`:
      return assign(
        {},
        {
          connectSuccess: null,
          disconnectSuccess: true,
          error: null,
        }
      );
    case `${types.CONNECT_TO_CREATOR}_FAILURE`:
    case `${types.DISCONNECT_FROM_CREATOR}_FAILURE`:
      return assign({}, state, {
        connectSuccess: null,
        disconnectSuccess: null,
        error: action.error,
      });
    case `${types.RESET_CONNECT}`:
      return connectInitState;
    default:
      return state;
  }
};

interface SessionData {
  current: Session[];
  past: Session[];
  upcoming: Session[];
}

interface SessionsInitState {
  data: SessionData;
  success: unknown;
  error: Record<string, unknown>;
}

const sessionsInitState: SessionsInitState = {
  data: {
    current: [],
    past: [],
    upcoming: [],
  },
  success: null,
  error: {},
};

const sessions = (state = sessionsInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_USER_SESSIONS}_REQUEST`:
      return assign({}, state, {
        data: {
          current: [],
          past: [],
          upcoming: [],
        },
        success: null,
        error: null,
      });
    case `${types.GET_USER_SESSIONS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data,
          error: null,
          success: true,
        }
      );
    case `${types.GET_USER_SESSIONS}_FAILURE`:
      return assign({}, state, {
        success: null,
        error: action.error,
      });
    default:
      return state;
  }
};

// User state
export interface UserState {
  signUpForm: SignUpFormInitState;
  currentTicket: TicketInitState;
  userForm: UserFormInitState;
  connect: ConnectInitState;
  sessions: SessionsInitState;
}
// Used for redux selector hooks
export interface UserReducer {
  user: UserState;
}

export default combineReducers({
  signUpForm,
  currentTicket,
  userForm,
  connect,
  sessions,
});
