declare global {
  interface Window {
    gtag: Function;
  }
}

/**
 * Create a pageview event in google analytics for client side navigation
 *
 * @param   {string}  url  URL of the page request
 *
 */
export const pageview = (url: string) => {
  if (window) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

export const event = ({action, params}) => {
  window.gtag('event', action, params);
};
