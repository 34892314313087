import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
// Helpers
import {Creator} from '@brightlive/shared/helpers/interfaces';

interface AllCreatorInitState {
  data: Array<Creator>[];
  success: boolean;
  error: boolean;
  loading: boolean;
}

const allCreatorInitState: AllCreatorInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allCreatorInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_ALL_CREATORS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_CREATORS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data ?? {},
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_ALL_CREATORS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

type InitialCreatorState = Creator & {
  success: boolean;
  created: boolean;
  updated: boolean;
  error: boolean;
};

const initialCreatorState: InitialCreatorState = {
  firstName: '',
  lastName: '',
  displayName: '',
  phone: '',
  email: '',
  avatar: '',
  id: '',
  backgroundImage: '',
  shortDescription: '',
  tags: [],
  sessions: [],
  pastSessions: [],
  socialLinks: [],
  success: false,
  created: false,
  updated: false,
  error: false,
};

const activeCreator = (state = initialCreatorState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_CREATOR}_SUCCESS`:
      return assign(
        {},
        {
          success: false,
          created: false,
          updated: false,
          error: false,
          ...action.data,
        }
      );
    case `${types.GET_CREATOR}_FAILURE`:
      return assign({}, initialCreatorState, {
        success: false,
        created: false,
        updated: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

interface CreatorState {
  all: AllCreatorInitState;
  activeCreator: InitialCreatorState;
}
// Used for redux selector hooks
export interface CreatorReducer {
  creator: CreatorState;
}

export default combineReducers({
  all,
  activeCreator,
});
