import Api, {AuthWrapper} from 'library/api';
import types from './types';
import firebase from 'firebase/compat/app';
import {logOutOtherSites} from '@brightlive/shared/helpers/auth';

/**
 * Logout user
 *
 * @param   {boolean}  ssoLogout  Whether to log out all other sites logged in via SSO. Default's to true, should only be set to false from the logout page loaded via iframe on logout
 *
 * @return {void}
 */
export const logout = (
  ssoLogout = true,
  authToken = ''
): {type: string; promise: Promise<void>} => ({
  type: types.LOGOUT,
  promise: new Promise((resolve, reject) => {
    const firebaseLogout = idToken => {
      firebase
        .auth()
        .signOut()
        .then(async () => {
          await Api.userLogout(idToken);
          if (ssoLogout) {
            await logOutOtherSites();
          }
          resolve();
        });
    };
    try {
      if (authToken) {
        firebaseLogout(authToken);
      } else {
        firebase
          .auth()
          .currentUser?.getIdToken()
          .then(idToken => {
            firebaseLogout(idToken);
          })
          .catch(() => reject());
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  }),
});

/**
 * Fetch the current user
 *
 * @return {void}
 */
export const getCurrentUser = (): {
  type: string;
  promise: AuthWrapper;
} => ({
  type: types.GET_CURRENT_USER,
  promise: Api.get('/users/current', {}),
});
/**
 * Fetch the current user
 *
 * @return {void}
 */
export const updateCurrentUser = data => ({
  type: types.UPDATE_CURRENT_USER,
  data,
});

/**
 * Fetch the current user
 *
 * @return {void}
 */
export const setContinueUrl = url => ({
  type: types.SET_CONTINUE_URL,
  url,
});

/**
 * Set auth token
 *
 * @return {void}
 */
export const setAuthToken = authToken => ({
  type: types.SET_AUTH_TOKEN,
  authToken,
});

/**
 * Update a user
 *
 * @return {void}
 */

export const updateUser = (
  data
): {
  type: string;
  promise: AuthWrapper;
} => ({
  type: types.UPDATE_USER,
  promise: Api.patch(`/users/${data.id}`, data),
});

/**
 * Resets success/error/updated states for a user
 *
 * @return {void}
 */

export const resetCurrentUserState = () => ({
  type: types.RESET_CURRENT_USER_STATE,
});
