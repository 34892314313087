import {AnyAction, combineReducers} from 'redux';
import {HYDRATE} from 'next-redux-wrapper';
// Reducers
import auth from 'redux/auth/reducer';
import ui from 'redux/ui/reducer';
import creator from 'redux/creator/reducer';
import session from 'redux/session/reducer';
import recording from 'redux/recording/reducer';
import user from 'redux/user/reducer';
import support from 'redux/support/reducer';
import pages from 'redux/pages/reducer';
import lesson from 'redux/lesson/reducer';
import video from 'redux/livekit/reducer';

const appReducer = combineReducers({
  auth,
  ui,
  creator,
  session,
  recording,
  user,
  support,
  pages,
  lesson,
  video,
});

let hydrated = false;
/**
 * Create redux Root Reducer
 *
 * @param   {RootReducer}  state current reducer state
 * @param   {AnyAction}  action current action triggered
 *
 * @return  {RootReducer} app reducer
 */
const rootReducer = (state, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      if (!hydrated) {
        hydrated = typeof window !== 'undefined';
        return {
          ...state,
          ...action.payload,
        };
      }
      return state;
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
