import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_SESSIONS: null,
  GET_SESSION: null,
  GET_STUDIO_SESSION: null,
  UPDATE_SESSION: null,
  CREATE_SESSION: null,
  UPDATE_SESSION_FORM: null,
  RESET_SESSION_FORM: null,
  GET_SESSION_RECORDINGS: null,
  GET_LEGACY_SESSION_RECORDINGS: null,
  UPDATE_PAYMENT_FORM: null,
  RESET_PAYMENT_FORM: null,
  SET_ACTIVE_SESSION: null,
  CREATE_PAYMENT_INTENT: null,
  RESET_ACTIVE_TICKET: null,
  ADD_TO_WAITLIST: null,
  REMOVE_FROM_WAITLIST: null,
  RESET_WAITLIST_ERRORS: null,
  RESET_ACTIVE_PAYMENT: null,
  CHECK_AVAILABILITY: null,
  SET_SESSION_CODE: null,
  GET_LIVESTREAM_KEYS: null,
  UPDATE_SIMULCAST_KEY: null,
});

export default types;
