import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
import {Lesson} from '@brightlive/shared/helpers/interfaces';

interface LessonInitState {
  data: Lesson;
  success: boolean;
  error: boolean | string;
  loading: boolean;
}

const lessonInitState: LessonInitState = {
  data: {
    id: '',
    title: '',
    creatorDescription: '',
    description: '',
    sessionID: '',
    session: {
      id: '',
      title: '',
      shortDescription: '',
      mainImage: '',
      // verticalImage: '',
      // video: '',
      ticketPrice: null,
      totalTickets: null,
      totalTicketsSold: 0,
      ticketsForSale: false,
      // timezone: '',
      // duration: '',
      status: 'draft',
      startDate: null,
      // isVisible: false,
      // isAnnouncement: false,
      started: false,
      ended: false,
      // isVisible: false,
      // isAnnouncement: false,
      createdAt: '',
    },
    sessionTitle: '',
    creatorID: '',
    creatorName: '',
    creatorSlug: '',
    categoryID: '',
    categoryName: '',
    subcategoryID: null,
    subcategoryName: null,
    tags: [],
    duration: 0,
    mainImage: '',
    verticalImage: '',
    videoStorageUrl: '',
    playbackID: '',
    muxAssetID: '',
    createdAt: '',
  },
  success: false,
  error: false,
  loading: false,
};

const active = (state = lessonInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_LESSON}_REQUEST`:
      return assign({}, lessonInitState, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_LESSON}_SUCCESS`:
      return assign(
        {},
        {
          data: {...action?.data},
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_LESSON}_FAILURE`:
      return assign({}, lessonInitState, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface LessonState {
  active: LessonInitState;
}
// Used for redux selector hooks
export interface LessonReducer {
  lesson: LessonState;
}

export default combineReducers({
  active,
});
