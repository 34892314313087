import {combineReducers} from 'redux';
import types from './types';
import {AnyAction} from 'redux';
import {assign} from 'lodash';

export interface TermsInitState {
  content: string;
  success: unknown;
  error: Record<string, unknown>;
}

const termsInitState: TermsInitState = {
  content: '',
  success: null,
  error: {},
};

const terms = (state = termsInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_TERMS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
      });
    case `${types.GET_TERMS}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: null,
          success: true,
        }
      );
    case `${types.GET_TERMS}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export interface PrivacyInitState {
  content: string;
  success: unknown;
  error: Record<string, unknown>;
}

const privacyInitState: PrivacyInitState = {
  content: '',
  success: null,
  error: {},
};

const privacy = (state = privacyInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_PRIVACY}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
      });
    case `${types.GET_PRIVACY}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: null,
          success: true,
        }
      );
    case `${types.GET_PRIVACY}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error,
      });
    default:
      return state;
  }
};
// Auth state
export interface SupportState {
  terms: TermsInitState;
  privacy: PrivacyInitState;
}
// Used for redux selector hooks
export interface SupportReducer {
  support: SupportState;
}

export default combineReducers({
  terms,
  privacy,
});
