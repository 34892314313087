import {spacing} from './theme/theme-core';

export const margin = {
  mobile: {
    page: spacing.MD,
  },
  desktop: {
    page: spacing.LG,
    panel: spacing.LG,
  },
};

export const sizing = {
  mobile: {
    navHeight: '80px',
    altNavHeight: '65px',
    footerControlsHeight: '100px',
  },
  desktop: {
    navHeight: '80px',
    altNavHeight: '80px',
    sessionCreatorMenuWidth: '240px',
    creatorSideMenuWidth: '288px',
    creatorSideMenuCondensedWidth: '72px',
    maxGridWidth: '940px',
    maxBookingWidth: '1128px',
    leftPanel: '616px',
    rightPanel: '300px',
    sessionSidePanelS: '345px',
    sessionSidePanelM: '375px',
    footerControlsHeight: '84px',
  },
};
