import keyMirror from 'keymirror';

const types = keyMirror({
  GET_HOMEPAGE: null,
  GET_HOMEPAGE_CATEGORIES: null,
  GET_ARCHIVE_CATEGORIES: null,
  GET_WATCH_PAGE: null,
});

export default types;
