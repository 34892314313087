'use strict';

import {AuthWrapper} from 'library/api';

/**
 * Promise middleware
 *
 * @return  {Promise}
 */
export default function promiseMiddleware({getState}) {
  return next =>
    async (action): Promise<boolean> => {
      const {type, ...rest} = action;
      let {promise} = action;

      if (!promise) return next(action);

      if (promise instanceof AuthWrapper) {
        promise = promise.execute(getState()?.auth?.auth?.authToken || '');
      }
      const SUCCESS = type + '_SUCCESS';
      const REQUEST = type + '_REQUEST';
      const FAILURE = type + '_FAILURE';

      next({
        ...rest,
        type: REQUEST,
      });

      try {
        const json = await promise;

        next({
          ...rest,
          data: json,
          type: SUCCESS,
        });

        return true;
      } catch (error) {
        next({
          ...rest,
          error,
          type: FAILURE,
        });

        return false;
      }
    };
}
