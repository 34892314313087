import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
// Helpers

export interface EditAssetConfig {
  id: string;
  url: string;
  trimStart?: number;
  duration?: number;
}

export interface SessionRecording {
  streamID: string;
  id: string;
  url: string;
  playbackID: string;
  audioOnly: boolean;
  duration: number;
  recordedAt: number;
  mode: string;
  status: 'preparing' | 'ready' | 'errored';
}

interface RecordingInitState {
  data: SessionRecording | null;
  success: boolean;
  delete: boolean;
  update: boolean;
  error: {message: string} | false;
  removed: boolean;
  loading: boolean;
}

const recordingInitState: RecordingInitState = {
  data: null,
  success: false,
  delete: false,
  update: false,
  error: false,
  loading: false,
  removed: false,
};
const recording = (state = recordingInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_RECORDING}_REQUEST`:
    case `${types.DELETE_RECORDING}_REQUEST`:
    case `${types.UPDATE_RECORDING}_REQUEST`:
    case `${types.CREATE_EDIT}_REQUEST`:
      return assign({}, state, {
        success: false,
        delete: false,
        update: false,
        error: false,
        loading: true,
      });
    case `${types.GET_RECORDING}_SUCCESS`:
    case `${types.CREATE_EDIT}_SUCCESS`:
      return assign(
        {},
        {
          data: {...action?.data} ?? null,
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.UPDATE_RECORDING}_SUCCESS`:
      return assign(
        {},
        {
          data: {...action?.data} ?? null,
          error: false,
          success: true,
          update: true,
          loading: false,
        }
      );
    case `${types.DELETE_RECORDING}_SUCCESS`:
      return assign(
        {},
        {
          data: {...action?.data} ?? null,
          error: false,
          success: true,
          delete: true,
          loading: false,
        }
      );
    case `${types.GET_RECORDING}_FAILURE`:
    case `${types.CREATE_EDIT}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    case `${types.DELETE_RECORDING}_FAILURE`:
      return assign({}, state, {
        success: false,
        delete: true,
        loading: false,
        error: action.error,
      });
    case `${types.UPDATE_RECORDING}_FAILURE`:
      return assign({}, state, {
        success: false,
        update: true,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface RecordingState {
  recording: RecordingInitState;
}
export interface RecordingReducer {
  recording: RecordingState;
}

export default combineReducers({
  recording,
});
