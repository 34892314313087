// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const Sentry = require('@sentry/nextjs');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const localDebug = false;
if (localDebug || process.env.NODE_ENV === 'production') {
  const sentryEnv =
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === 'bright-live-production'
      ? 'production'
      : 'stage';
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://c9ba9fae53af4afe98a6fe933786fbe8@o550310.ingest.sentry.io/5673765',
    environment: sentryEnv,
  });
}
