import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
import recordingTypes from 'redux/recording/types';
import {Timestamp} from 'firebase/firestore';
// Helpers
import {Session, Simulcast} from '@brightlive/shared/helpers/interfaces';

interface AllSessionInitState {
  data: Session[];
  success: boolean;
  error: boolean | string;
  loading: boolean;
}

const allSessionInitState: AllSessionInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

interface WaitlistInitState {
  data: Array<Record<string, unknown>>[];
  success: boolean;
  error: boolean | string;
  removed: boolean;
  loading: boolean;
}

const waitlistInitState: WaitlistInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
  removed: false,
};
export interface SessionRecording {
  id: string;
  title: string;
  streamID: string;
  url: string;
  playbackID: string;
  audioOnly: boolean;
  duration: number;
  createdAt: Timestamp;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assets: any;
  mode: string;
  status: 'preparing' | 'ready' | 'errored';
}

const all = (state = allSessionInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_ALL_SESSIONS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_SESSIONS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data ?? {},
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_ALL_SESSIONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export type InitialSessionState = Session & {
  success: boolean;
  created: boolean;
  updated: boolean;
  error: boolean;
  code: string;
};

const initialActiveSessionState: InitialSessionState = {
  id: '',
  // webinarID: '',
  title: '',
  shortDescription: '',
  // longDescription: '',
  mainImage: '',
  // verticalImage: '',
  // video: '',
  status: 'upcoming',
  startDate: '',
  // duration: '',
  // timezone: '',
  // slug: '',
  stream: false,
  ticketPrice: 0,
  totalTickets: 0,
  totalTicketsSold: 0,
  streamUrl: '',
  // isAnnouncement: false,
  // isVisible: false,
  inviteTokenGuest: '',
  inviteTokenMod: '',
  livestreams: [],
  ticketsForSale: false,
  started: false,
  ended: false,
  createdAt: '',
  success: false,
  created: false,
  updated: false,
  error: false,
  code: '',
};

const activeSession = (
  state = initialActiveSessionState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.GET_SESSION}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.UPDATE_SIMULCAST_KEY}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.SET_SESSION_CODE}`:
      return assign({}, state, {
        code: action.code,
      });
    case `${types.GET_SESSION}_SUCCESS`:
    case `${types.SET_ACTIVE_SESSION}`:
      return assign({}, state, {
        success: true,
        created: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.UPDATE_SESSION}_SUCCESS`:
      return assign({}, state, {
        success: false,
        updated: true,
        error: null,
        ...action.data,
      });
    case `${types.GET_SESSION}_FAILURE`:
      return assign({}, initialSessionFormState, {
        data: [],
        success: false,
        created: false,
        updated: false,
        error: action.error.message,
      });
    case `${types.ADD_TO_WAITLIST}_SUCCESS`:
    case `${types.REMOVE_FROM_WAITLIST}_SUCCESS`:
      return assign({}, state, {waitlist: action.data.waitlist});
    default:
      return state;
  }
};

export type InitialLivestreamState = {
  simulcasts: Simulcast[];
  success: boolean;
  error: boolean;
};

const initialLivestreamState: InitialLivestreamState = {
  simulcasts: [],
  success: false,
  error: false,
};

const livestreams = (state = initialLivestreamState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_LIVESTREAM_KEYS}_REQUEST`:
      return initialSessionFormState;
    case `${types.GET_LIVESTREAM_KEYS}_SUCCESS`:
    case `${types.UPDATE_SIMULCAST_KEY}_SUCCESS`:
      return assign({}, state, {
        ...action.data,
        success: true,
        error: false,
      });
    case `${types.GET_LIVESTREAM_KEYS}_FAILURE`:
    case `${types.UPDATE_SIMULCAST_KEY}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

interface InitialTicketState {
  stripeClientSecret: string;
  finalAmount: number | null;
  serviceFees: number | null;
}

const initialTicketState: InitialTicketState = {
  stripeClientSecret: '',
  finalAmount: null,
  serviceFees: null,
};

const activePayment = (state = initialTicketState, action: AnyAction) => {
  switch (action.type) {
    case `${types.CREATE_PAYMENT_INTENT}_SUCCESS`:
      return assign({}, state, {
        ...action.data,
      });
    case `${types.RESET_ACTIVE_PAYMENT}`:
      return initialTicketState;
    default:
      return state;
  }
};

const waitlist = (state = waitlistInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.ADD_TO_WAITLIST}_REQUEST`:
    case `${types.REMOVE_FROM_WAITLIST}_REQUEST`:
    case `${types.RESET_WAITLIST_ERRORS}`:
      return waitlistInitState;
    case `${types.ADD_TO_WAITLIST}_SUCCESS`:
      return assign({}, state, {
        success: true,
        created: false,
        updated: false,
        error: false,
        removed: false,
        ...action.data,
      });
    case `${types.REMOVE_FROM_WAITLIST}_SUCCESS`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
        removed: true,
        ...action.data,
      });
    case `${types.ADD_TO_WAITLIST}_FAILURE`:
    case `${types.REMOVE_FROM_WAITLIST}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

interface InitialRecordingsState {
  data: SessionRecording[];
  success?: boolean;
  error?: null | {message: string};
  loading: boolean;
}

const initialRecordingsState: InitialRecordingsState = {
  data: [],
  success: false,
  error: null,
  loading: false,
};

const recordings = (state = initialRecordingsState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_SESSION_RECORDINGS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_SESSION_RECORDINGS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data ?? {},
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${recordingTypes.DELETE_RECORDING}_SUCCESS`:
      return assign(state, {
        data: state.data.filter(recording => recording.id !== action.data.id),
      });
    case `${recordingTypes.UPDATE_RECORDING}_SUCCESS`:
      return assign(state, {
        data: state.data.map(recording =>
          recording.id === action.data.id ? {...action.data} : recording
        ),
      });
    case `${types.GET_SESSION_RECORDINGS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface InitialLegacyRecordingsState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: {recordings: any[]};
  success?: boolean;
  error?: null | {message: string};
  loading: boolean;
}

const initialLegacyRecordingsState: InitialLegacyRecordingsState = {
  data: {recordings: []},
  success: false,
  error: null,
  loading: false,
};

const legacyRecordings = (
  state = initialLegacyRecordingsState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.GET_LEGACY_SESSION_RECORDINGS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_LEGACY_SESSION_RECORDINGS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data ?? {},
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_LEGACY_SESSION_RECORDINGS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export type InitialSessionFormState = Session & {
  created?: boolean;
  success?: boolean;
  updated?: boolean;
  error?: null | {message: string};
};

const initialSessionFormState: InitialSessionFormState = {
  createdAt: new Date(),
  id: '',
  mainImage: '',
  shortDescription: '',
  startDate: null,
  status: 'upcoming',
  title: '',
  created: false,
  success: false,
  updated: false,
  error: null,
};

const sessionForm = (state = initialSessionFormState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_STUDIO_SESSION}_REQUEST`:
    case `${types.CREATE_SESSION}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: null,
      });
    case `${types.RESET_SESSION_FORM}`:
      return assign({}, initialSessionFormState, {
        success: false,
        created: false,
        updated: false,
        error: null,
      });
    case `${types.GET_STUDIO_SESSION}_SUCCESS`:
    case `${types.UPDATE_SESSION_FORM}`:
      return assign({}, state, {
        success: true,
        created: false,
        updated: false,
        error: null,
        ...action.data,
        ...action.data?.session,
      });
    case `${types.CREATE_SESSION}_SUCCESS`:
      return assign({}, state, {
        success: false,
        created: true,
        updated: false,
        error: null,
        ...action.data,
      });
    case `${types.GET_STUDIO_SESSION}_FAILURE`:
    case `${types.CREATE_SESSION}_FAILURE`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

interface SessionState {
  all: AllSessionInitState;
  activeSession: InitialSessionState;
  livestreams: InitialLivestreamState;
  waitlist: WaitlistInitState;
  activePayment: InitialTicketState;
  recordings: InitialRecordingsState;
  legacyRecordings: InitialLegacyRecordingsState;
  sessionForm: InitialSessionFormState;
}
// Used for redux selector hooks
export interface SessionReducer {
  session: SessionState;
}

export default combineReducers({
  all,
  activeSession,
  livestreams,
  waitlist,
  activePayment,
  recordings,
  legacyRecordings,
  sessionForm,
});
