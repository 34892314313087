import keyMirror from 'keymirror';

const types = keyMirror({
  DELETE_RECORDING: null,
  UPDATE_RECORDING: null,
  GET_RECORDING: null,
  CREATE_EDIT: null,
});

export default types;
