import keyMirror from 'keymirror';

const types = keyMirror({
  SET_TOKEN: null,
  SET_CURRENT_TAB: null,
  TOGGLE_FLAG_USER_MODAL: null,
  TOGGLE_DELETE_COMMENT_MODAL: null,
});

export default types;
