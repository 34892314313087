import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_CREATORS: null,
  GET_CREATOR: null,
  CREATE_CREATOR: null,
  EDIT_CREATOR: null,
  DELETE_CREATOR: null,
  UPDATE_CREATOR_FORM: null,
  RESET_CREATOR_FORM: null,
});

export default types;
