import React from 'react';
import App from 'next/app';
import {reduxWrapper} from 'redux/store';
import GlobalStyles from 'styles/globalStyles';
import {ThemeProvider} from 'styled-components';
import {theme} from '@brightlive/shared/styles/theme';
import {AuthProvider} from 'components/shared/AuthProvider';
import NProgress from 'nprogress';
import {pageview} from 'library/helpers/ga';

import {initFirebase} from '../library/firebase';
import {Router} from 'next/router';

// Styles
import 'video.js/dist/video-js.css';
import Head from 'next/head';

initFirebase(); // init Firebase

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeComplete', handleRouteChange);
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({showSpinner: false});

function handleRouteChange(url: string) {
  pageview(url);
}

class CustomApp extends App {
  /**
   * Render
   *
   * @return  {JSX.Element}
   */

  static getInitialProps = reduxWrapper.getInitialAppProps(
    store =>
      async ({Component, ctx}) => {
        return {
          pageProps: {
            ...(Component.getInitialProps
              ? await Component.getInitialProps({
                  ...ctx,
                  store,
                })
              : {}),
          },
        };
      }
  );
  render() {
    const {Component, pageProps} = this.props;
    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Component {...pageProps} />
            <div
              id="grecaptcha-button"
              style={{opacity: 0, pointerEvents: 'none'}}
            />
          </ThemeProvider>
        </AuthProvider>
      </>
    );
  }
}
export default reduxWrapper.withRedux(CustomApp);
