import 'styled-components';
import {assign} from 'lodash';
import {
  elevation,
  spacing,
  borderRadius,
  borderWidth,
  fontSize,
  lineHeight,
  fontWeight,
  letterSpacing,
  typeface,
  opacity,
} from './theme/theme-core';

import {margin, sizing} from './theme-custom';

import {color} from './theme/theme-core-color';

import {
  actionColor,
  backgroundColor,
  borderColor,
  contentColor,
  focusColor,
} from './theme/theme-light';

import {tag} from './theme/theme-light-tag';

const theme = assign(
  {color},
  {actionColor},
  {backgroundColor},
  {contentColor},
  {borderColor},
  {focusColor},
  {elevation},
  {spacing},
  {margin},
  {borderRadius},
  {borderWidth},
  {typeface},
  {fontSize},
  {lineHeight},
  {fontWeight},
  {letterSpacing},
  {sizing},
  {opacity},
  {tag}
);

export {theme};
