export const color = {
  neon: {
    '500': '#E0FE2A',
    '600': '#CAEC01',
    '700': '#B1CE01',
  },
  white: '#FFFFFF',
  keylimeGreen: '#E4F882',
  neonYellow: '#F4FE03',
  lightOrange: '#FFB200',
  brown: {
    '3': '#D9C9BD',
  },
  grey: {
    '25': '#F7F7F7',
    '50': '#F3F3F3',
    '100': '#E8E7E7',
    '200': '#DCDCDB',
    '300': '#D0D1CF',
    '400': '#B6B8B6',
    '500': '#9EA09E',
    '600': '#858786',
    '700': '#6D6F6F',
    '800': '#555657',
    '900': '#3D3D3E',
  },
  wgrey: {
    '25': '#F8F7F5',
    '50': '#F5F3EF',
    '100': '#EFECE6',
    '200': '#E5E2DC',
    '300': '#DBD8D2',
    '400': '#C8C5BE',
    '500': '#A19E97',
    '600': '#7A7772',
    '700': '#67645F',
    '800': '#54514D',
    '900': '#403E3B',
  },
  black: '#252526',
  veryBlack: '#131313',
  trueBlack: '#000000',
  pink: {
    '100': '#FEE0FF',
    '200': '#F6C5F7',
    '300': '#EEA4F0',
    '400': '#DE70E0',
    '500': '#BC3EBF',
    '600': '#A02DA2',
    '700': '#831F85',
    '800': '#4B0F4C',
  },
  red: {
    '100': '#FFDBDB',
    '200': '#FDB9BB',
    '300': '#FB989C',
    '400': '#F75862',
    '500': '#DF1027',
    '600': '#B7001D',
    '700': '#84OO1B',
    '800': '#540015',
  },
  scarlet: {
    '100': '#F7DBD1',
    '200': '#EFB8A3',
    '300': '#E89475',
    '400': '#E07047',
    '500': '#CE5123',
    '600': '#7C3015',
    '700': '#7C3015',
    '800': '#53200E',
  },
  orange: {
    '100': '#FFEBC4',
    '200': '#FEDA90',
    '300': '#F9BE76',
    '400': '#EE8B36',
    '500': '#E35928',
    '600': '#CF3F0B',
    '700': '#A72F04',
    '800': '#8A2500',
  },
  cactus: {
    '100': '#F1F1D5',
    '200': '#E2E4AA',
    '300': '#D4D680',
    '400': '#C5C956',
    '500': '#ABAF38',
    '600': '#898C2D',
    '700': '#676922',
    '800': '#444616',
  },
  green: {
    '100': '#DCFAE6',
    '200': '#C7F7D8',
    '300': '#A1E8BE',
    '400': '#6DCB99',
    '500': '#32A171',
    '600': '#04724D',
    '700': '#015237',
    '800': '#003322',
  },
  indigo: {
    '100': '#E9EAFF',
    '200': '#DDDEFF',
    '300': '#C6C8FF',
    '400': '#B6BAFF',
    '500': '#A7ABFF',
    '600': '#787EFF',
    '700': '#4951FF',
    '800': '#00068D',
  },
  blue: {
    '100': '#E4EBFF',
    '200': '#CED9FD',
    '300': '#B7C7FB',
    '400': '#7D94E9',
    '500': '#4966D7',
    '600': '#1D38B6',
    '700': '#16217D',
    '800': '#00084D',
  },
};
