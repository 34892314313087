import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  Store,
  AnyAction,
} from 'redux';
import {createWrapper} from 'next-redux-wrapper';
import rootReducer from './root-reducer';
import middlewares from 'middlewares';
import {NextPageContext} from 'next';
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
export interface BrightPageContext extends NextPageContext {
  store: Store;
  ctx: NextPageContext;
}

/**
 * Create redux store
 *
 * @param   {Context}  context
 *
 * @return  {Store}
 */
// create a makeStore function
let store: Store<AnyAction>;
const makeStore = () => {
  store = createStore(rootReducer, enhancer);
  return store;
};

export {store};

// export an assembled wrapper
export const reduxWrapper = createWrapper(makeStore);
