import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
// Helpers
import {Session, Lesson} from '@brightlive/shared/helpers/interfaces';
interface HeroSessions {
  session: Session;
  image: string;
  title: string;
  order: number;
}
interface FeaturedSessions {
  title: string;
  sessions: Session[];
}
interface HomepageInitState {
  heroSessions: Array<HeroSessions>;
  featuredSessions: FeaturedSessions;
  success: boolean;
  error: boolean;
  loading: boolean;
}

const homepageInitState: HomepageInitState = {
  heroSessions: [],
  featuredSessions: {
    title: '',
    sessions: [],
  },
  success: false,
  error: false,
  loading: false,
};

const homepage = (state = homepageInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_HOMEPAGE}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_HOMEPAGE}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_HOMEPAGE}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface HeroVideos {
  lesson: Lesson;
  image: string;
  title: string;
  order: number;
}
interface Carousels {
  order: number;
  title: string;
  description?: string;
  search: string;
  lessons: Array<Lesson>;
}

interface WatchPageInitState {
  heroVideos: Array<HeroVideos>;
  carousels: Array<Carousels>;
  success: boolean;
  error: boolean;
  loading: boolean;
}

const watchPageInitState: WatchPageInitState = {
  heroVideos: [],
  carousels: [],
  success: false,
  error: false,
  loading: false,
};

const watchPage = (state = watchPageInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_WATCH_PAGE}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_WATCH_PAGE}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_WATCH_PAGE}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};
interface HomepageCategoriesInitState {
  wellness: Array<Session>;
  lifestyle: Array<Session>;
  career: Array<Session>;
  creativity: Array<Session>;
  relationship: Array<Session>;
  success: boolean;
  error: boolean;
  loading: boolean;
}

const homepageCategoriesInitState: HomepageCategoriesInitState = {
  wellness: [],
  lifestyle: [],
  career: [],
  creativity: [],
  relationship: [],
  success: false,
  error: false,
  loading: false,
};

const homepageCategories = (
  state = homepageCategoriesInitState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.GET_HOMEPAGE_CATEGORIES}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_HOMEPAGE_CATEGORIES}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_HOMEPAGE_CATEGORIES}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface ArchiveCategoriesInitState {
  wellness: Array<Session>;
  lifestyle: Array<Session>;
  career: Array<Session>;
  creativity: Array<Session>;
  relationship: Array<Session>;
  success: boolean;
  error: boolean;
  loading: boolean;
}

const archiveCategoriesInitState: ArchiveCategoriesInitState = {
  wellness: [],
  lifestyle: [],
  career: [],
  creativity: [],
  relationship: [],
  success: false,
  error: false,
  loading: false,
};

const archiveCategories = (
  state = archiveCategoriesInitState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.GET_ARCHIVE_CATEGORIES}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ARCHIVE_CATEGORIES}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: false,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_ARCHIVE_CATEGORIES}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

interface PagesState {
  homepage: HomepageInitState;
  homepageCategories: HomepageCategoriesInitState;
  archiveCategories: ArchiveCategoriesInitState;
  watchPage: WatchPageInitState;
}
// Used for redux selector hooks
export interface PagesReducer {
  pages: PagesState;
}

export default combineReducers({
  homepage,
  watchPage,
  homepageCategories,
  archiveCategories,
});
