import {combineReducers} from 'redux';
import types from './types';
import {AnyAction} from 'redux';
import {assign} from 'lodash';
import {BrightParticipant} from 'bright-livekit';

interface initialTokenState {
  token: string;
}

const initialTokenState: initialTokenState = {
  token: '',
};

const setToken = (state = initialTokenState, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_TOKEN}`:
      return assign({}, state, {
        token: action.token,
      });
    default:
      return state;
  }
};

interface initialFlagUserState {
  toggled: boolean;
  participant: BrightParticipant | null;
}

const initialFlagUserState: initialFlagUserState = {
  toggled: false,
  participant: null,
};

const flagUserModal = (state = initialFlagUserState, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_FLAG_USER_MODAL}`:
      return assign({}, state, {
        toggled: action.data.toggled,
        participant: action.data.participant,
      });
    default:
      return state;
  }
};

interface initialDeleteCommentState {
  toggled: boolean;
  participant: BrightParticipant | null;
}

const initialDeleteCommentState: initialDeleteCommentState = {
  toggled: false,
  participant: null,
};

const deleteCommentModal = (
  state = initialDeleteCommentState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.TOGGLE_DELETE_COMMENT_MODAL}`:
      return assign({}, state, {
        toggled: action.data.toggled,
        participant: action.data.participant,
      });
    default:
      return state;
  }
};

const currentTab = (state = 'questions', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_CURRENT_TAB}`:
      return action.tab;
    default:
      return state;
  }
};

interface VideoState {
  setToken: initialTokenState;
  currentTab: 'chat' | 'attendees' | 'recordings' | 'questions';
  flagUserModal: initialFlagUserState;
  deleteCommentModal: initialDeleteCommentState;
}

export interface VideoReducer {
  video: VideoState;
}

export default combineReducers({
  setToken,
  currentTab,
  flagUserModal,
  deleteCommentModal,
});
